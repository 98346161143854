import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import DataList from '../components/DataList'

const IndexPage = () => (
  <Layout>
    <SEO title="Kawasaki Customer Website Locator" />
    <section>
      <div className="list lg content">
        <div className="list">
          <h1>
            welcome to <span className="color-p1 nowrap">Kawasaki Engines</span>
          </h1>
          <p>
            Please choose your country from the list below
            for the best experience.
          </p>
        </div>
        <DataList />
      </div>
    </section>
  </Layout>
)

export default IndexPage
