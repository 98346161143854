import React, {useState, useRef, FormEvent} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {
  CountryForm,
  InputFieldWrapper,
  Button,
  ErrorMsg,
  ArrowImage,
  CountryInputField,
} from './styles';
import Arrow from '../../assets/icons/arrow.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Leaving this here for now in case we want to deeupte the countries
// const dedupeCountries = memoize((countries: CountryItem[]) => {
//   const deduped = countries.reduce((final, current) => {
//     return {
//       ...final,
//       [current.Country]: {
//         ...current,
//       },
//     };
//   }, {});
//   return Object.values(deduped);
// });

const DataList = () => {
  const {allCountriesCsv} = useStaticQuery(
    graphql`
      query {
        allCountriesCsv {
          nodes {
            Country
            URL
          }
        }
      }
    `,
  );

  const countries = allCountriesCsv.nodes;

  const [error, setError] = useState(false);
  const [showList] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const submitButton = useRef(null);

  function submitData(e: FormEvent) {
    e.preventDefault();

    if (!selectedValue) return setError(true);

    setError(false);

    let url = selectedValue.URL.match(/^https?:/)
      ? selectedValue.URL
      : '//' + selectedValue.URL;

    selectedValue.URL != '' ? window.open(url, '_blank') : setError(true);
  }

  return (
    <CountryForm onSubmit={e => submitData(e)}>
      <Autocomplete
        options={countries}
        getOptionLabel={(option: CountryItem) => option.Country}
        autoSelect={true}
        openOnFocus={true}
        onChange={(e, value, reason) => {
          setSelectedValue(value);
        }}
        renderInput={params => {
          return (
            <InputFieldWrapper ref={params.InputProps.ref}>
              <CountryInputField
                {...params.inputProps}
                placeholder="TYPE OR FIND YOUR COUNTRY"
              />
              <ArrowImage src={Arrow} alt="arrow icon" />
            </InputFieldWrapper>
          );
        }}
      />
      <Button
        type="submit"
        enabled={selectedValue}
        showList={showList}
        ref={submitButton}
      >
        GO
      </Button>
      {!error || (
        <ErrorMsg>
          For inquiries about service, please contact your equipment's
          manufacturer.
        </ErrorMsg>
      )}
    </CountryForm>
  );
};

export default DataList;
