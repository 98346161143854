import styled from 'styled-components';
import ArrowSVG from '../../assets/icons/arrow.svg';

export const CountryForm = styled.form`
  grid-column-gap: 16px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: auto auto auto;

  justify-content: center;
  max-width: 510px;
  width: 100%;
  margin: auto;
  position: relative;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`;

export const InputFieldWrapper = styled.div`
  width: 100% !important;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #000;

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 5px;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  @media (max-width: 600px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

export const CountryInputField = styled.input`
  all: unset;
  height: 100%;
  align-items: center;
  padding: 0;
  font-size: 32px;
  text-transform: uppercase;
  text-align: left;
  font-family: 'Trade Gothic LT W05 Bd Cn No-2', sans-serif;
  letter-spacing: -0.03em;
  z-index: 2;
  padding-right: 2px;

  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: 2;

  @media (max-width: 600px) {
    font-size: 26px;
  }

  label {
    font-family: 'Trade Gothic LT W05 Bd Cn No-2', sans-serif;
    font-size: 32px;
    text-transform: uppercase;
  }
`;

export const ArrowImage = styled(ArrowSVG)`
  cursor: pointer;
  z-index: 2;
  height: 10px;
  transform: rotate(-90deg);
  grid-column-start: 2;
  grid-row-start: 1;
`;

export const CountryDatalist = styled.select<{showList: boolean}>`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  display: ${props => (props.showList ? 'block' : 'none')};

  border-radius: 0 0 5px 5px;
  border-color: transparent;
  box-shadow: 5px 5px 15px -4px rgb(206, 206, 206);

  cursor: pointer;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none;

  position: absolute;
  width: 100%;
  top: 0;

  option {
    cursor: pointer;
    text-align: left;
    padding: 15px;
    background: white;
    :hover {
      background: rgb(206, 206, 206);
    }
  }
  max-height: 200px;
`;

export const Button = styled.button<{enabled: boolean; showList: boolean}>`
  background-color: ${props =>
    props.enabled ? 'var(--theme-p1)' : 'var(--theme-disabled)'};
  display: block;
  color: #fff;
  text-transform: uppercase;
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  height: 50px;
  border: 0;
  transition: background-color 0.3s;
  padding: 0 22px;
  font-weight: bold;
  font-family: 'Trade Gothic LT W05 Bd Cn No-2', sans-serif;
  font-size: 19px;

  margin: 0;

  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  @media (max-width: 600px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin: 16px 0 0;
    display: ${props => (props.showList ? 'none' : 'block')};
  }
`;
export const ErrorMsg = styled.p`
  color: var(--theme-p1);
  font-style: italic;
  text-align: left;

  margin: 16px 0;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;

  @media (max-width: 600px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
`;
