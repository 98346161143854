import styled from 'styled-components';
import Img from 'gatsby-image';
import LogoSVG from '../../assets/images/kawi-logo.svg';
import StripesSVG from '../../assets/images/stripes.svg';
import GatsbyImageProps from 'gatsby-image';
import Link from 'gatsby-link';

const colors = {
  red: '#E60012',
  grey: '#979797',
  black: '#000',
};
export const FooterContainer = styled.div`
  padding-top: 100px;
  background-color: ${colors.red};
`;
export const Footer = styled.footer`
  background-color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.5rem, 1.5vw), 8px);
  padding: 15px 30px;
  position: relative;
  font-family: 'Trade Gothic LT W05 Roman', sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
  }
`;

export const Copyright = styled.div`
  color: white;
  z-index: 2;
  position: relative;
  width: 20em;
  font-size: 1.6em;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    text-align: center;
  }
  @media screen and (max-width: 1280px) and (min-width: 1025px) {
    font-size: 0.98em;
  }
  @media screen and (max-width: 1366px) and (min-width: 1281px) {
    font-size: 1.14em;
  }
  @media screen and (max-width: 1440px) and (min-width: 1337px) {
    font-size: 1.2em;
  }
  @media screen and (max-width: 1680px) and (min-width: 1441px) {
    font-size: 1.36em;
  }
`;

export const MapImage = styled(Img)<GatsbyImageProps>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 80vw;
  max-width: 100%;
  object-fit: contain;
  z-index: 1;

  @media (max-width: 768px) {
    height: 40vh;
    width: 100vw;
  }
  @media (max-width: 420px) {
    height: 40vh;
    width: 100vw;
  }
`;

export const Logo = styled(LogoSVG)`
  height: 69px;
  width: 260px;

  @media (max-width: 800px) {
    width: 152px;
    height: 40.33px;
  }
`;

export const Stripes = styled(StripesSVG)`
  height: 17px;
  object-fit: cover;
  width: 100%;
  max-width: 1117px;
`;

export const FooterLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
  margin: 0;
  max-width: 100%;
  z-index: 3;
  position: relative;
  list-style-type: none;
  flex-wrap: wrap;
  flex: 2 2 auto;
  background: #000;
  font-size: 1.6em;

  @media (max-width: 1024px) {
    justify-content: center;
    flex: 0 0 auto;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1280px) and (min-width: 1025px) {
    font-size: 0.98em;
  }
  @media screen and (max-width: 1366px) and (min-width: 1281px) {
    font-size: 1.14em;
  }
  @media screen and (max-width: 1440px) and (min-width: 1337px) {
    font-size: 1.2em;
  }
  @media screen and (max-width: 1680px) and (min-width: 1441px) {
    font-size: 1.36em;
  }
`;

export const FooterLinkItem = styled.li`
  margin: 0 2.5px;
  line-height: 1.2em;
  &:not(:last-child):after {
    content: '|';
    color: #fff;
  }
`;

export const FooterLink = styled(Link)`
  color: white;
  font-family: 'Trade Gothic LT W05 Roman', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5em;

  text-decoration: none;
  opacity: 1;
  transition: all 0.2s linear;

  &:hover {
    color: #eeeeee;
  }
`;
