import * as React from 'react';
import './styles.css';
import {Stripes, Logo} from './styles';
import {useStaticQuery, graphql} from 'gatsby';
import {
  MapImage,
  FooterContainer,
  FooterLinks,
  FooterLinkItem,
  FooterLink,
  Footer,
  Copyright,
} from './styles';

const FOOTER_LINKS = [
  {
    href: 'https://www.kawasakienginesusa.com/privacy-policy.html',
    label: 'US Privacy Policy',
  },
  {
    href:
      'https://www.kawasakienginesusa.com/legal-notices/terms-and-conditions-use-kawasaki-motors-corp-usa-websites.html',
    label: 'US Terms of Use',
  },
  {
    href: 'https://www.kawasaki-engines.eu/en/privacy-policy/',
    label: 'EU/UK/Middle East/Africa Privacy Policy',
  },
  {
    href: ' https://www.kawasaki-engines.eu/en/cookies/',
    label: 'EU/UK/Middle East/Africa Cookies',
  },
  {
    href: 'https://www.kawasakienginesusa.com/ccpa.html',
    label: 'California Privacy Rights',
  },
  {
    href: 'https://www.kawasakienginesusa.com/ccpa.html#non-discrimination',
    label: 'US Financial Disclosure',
  },
  {
    href:
      'https://privacyportal.onetrust.com/webform/045ffde2-647c-41d8-8fd2-848407f04871/15c56c27-7325-4414-b115-28163f6dbc52',
    label: 'Do Not Sell My Personal Information',
  },
  {
    label: 'Manage Privacy Preferences',
    className: 'optanon-toggle-display',
  },
  {
    href: 'https://www.kawasakienginesusa.com/privacy-policy.html#section11',
    label: 'About Ads',
  },
];

const Layout = ({children}: {children: JSX.Element | JSX.Element[]}) => {
  const {mapImage} = useStaticQuery(graphql`
    query {
      mapImage: file(relativePath: {eq: "map.png"}) {
        childImageSharp {
          id
          fluid(maxWidth: 1248) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="app">
      <header>
        <Stripes />
        <Logo />
      </header>
      <main>{children}</main>
      <FooterContainer>
        <Footer>
          <Copyright>© Kawasaki Engines Copyright 2021</Copyright>
          <MapImage
            critical
            fadeIn={false}
            fluid={mapImage.childImageSharp.fluid}
            style={{
              position: 'absolute',
            }}
          />
          <FooterLinks>
            {FOOTER_LINKS.map(({href, label, className}) => (
              <FooterLinkItem key={href}>
                <FooterLink href={href} className={className} target="_blank">
                  {label} &nbsp;
                </FooterLink>
              </FooterLinkItem>
            ))}
          </FooterLinks>
        </Footer>
      </FooterContainer>
    </div>
  );
};

export default Layout;
